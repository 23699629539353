<template>
  <!--<nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/reportedcase">Report</router-link> |
    <router-link to="/boreholestate">Borehole</router-link> |
    <router-link to="/feeds">Feeds</router-link>
  </nav>
  <router-view/>-->

  <nav>
    <Header />
    <router-view />
    <Footer />
  </nav>

  <!--<div id="app">
    <Notification
      v-if="notification"
      :message="notification.message"
      @dismiss="dismissNotification"
    />

  </div>-->
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";


export default {
  components: {
    Header,
    Footer,
    Notification
  },
  /*data() {
    return {
      notification: null,
    };
  },
  components: {
    Notification,
  },
  methods: {
    showNotification(message) {
      this.notification = { message };
    },
    dismissNotification() {
      this.notification = null;
    },
  }, */
};

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/*nav {
  padding: 30px;
}*/

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}


</style>
