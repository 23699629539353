import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

 // Import the Vuetify configuration

createApp(App)
  .use(store)
  .use(router)
  .mount('#app')
