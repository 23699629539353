<template>
  <footer class="bg-[#0058a8] text-white py-5">
    <div class="container mx-auto px-4">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-left"> <!-- Added text-left here -->
        <!-- About Us Section -->
        <div class="col-md-4 mb-5">
          <a href="#" class="text-decoration-none">
            <h1 class="mb-4 display-5 font-weight-bold text-[#f48a26] uppercase">
              <span class="text-warning font-bold border border-white px-3 mr-1">J</span>anga Voice
            </h1>
          </a>
          <p>{{ footerData ? footerData.description : 'Loading...' }}</p>
        </div>

        <!-- Quick Links Section -->
        <div class="col-md-4 mb-5">
          <h3 class="font-bold text-[#f48a26] mb-4 uppercase">QUICK LINKS</h3>
          <ul v-if="footerData && footerData.water_source_status_link">
            <li><a :href="footerData.home_link" class="text-white mb-2"><i class="fa fa-angle-right mr-2"></i>Home</a></li>
            <li><a :href="footerData.about_link" class="text-white mb-2"><i class="fa fa-angle-right mr-2"></i>About</a></li>
            <li><a :href="footerData.report_case_link" class="text-white mb-2"><i class="fa fa-angle-right mr-2"></i>Report Case</a></li>
            <li><a :href="footerData.water_source_status_link" class="text-white mb-2"><i class="fa fa-angle-right mr-2"></i>Water Source Status</a></li>
            <li><a :href="footerData.feeds_link" class="text-white mb-2"><i class="fa fa-angle-right mr-2"></i>Feeds</a></li>
            <li><a :href="footerData.contact_link" class="text-white"><i class="fa fa-angle-right mr-2"></i>Contact</a></li>
          </ul>
          <p v-else>Loading...</p>
        </div>

        <!-- Connect With Us Section -->
        <div class="col-md-4 mb-5">
          <h3 class="font-bold text-[#f48a26] mb-4 uppercase">CONNECT WITH US</h3>
          <ul class="space-y-2">
            <li><a :href="footerData ? footerData.facebook_link : '#'" target="_blank" class="text-white mb-2"><i class="fa fa-angle-right mr-2"></i>Facebook</a></li>
            <li><a :href="footerData ? footerData.twitter_link : '#'" target="_blank" class="text-white mb-2"><i class="fa fa-angle-right mr-2"></i>Twitter</a></li>
            <li><a :href="footerData ? footerData.instagram_link : '#'"  target="_blank" class="text-white"><i class="fa fa-angle-right mr-2"></i>Instagram</a></li>
            <li><a :href="footerData ? footerData.linkedin_link : '#'" target="_blank" class="text-white"><i class="fa fa-angle-right mr-2"></i>LinkedIn</a></li>
          </ul>
        </div>
      </div>
      <hr />

      <!-- Copyright Section -->
      <div class="row border-top border-light mx-auto py-4 text-center">
        <div class="col-md-12">
          <p class="mb-md-0 text-[#f48a26] font-bold">
            &copy; Janga Voice || All rights reserved. || {{ new Date().getFullYear() }}.
          </p>
        </div>
        <div class="col-md-12">
          <img class="img-fluid" src="img/payments.png" alt="">
        </div>
      </div>

      <!-- Back to Top -->
      <a v-if="showBackToTop" href="#" class="back-to-top" @click.prevent="scrollToTop">
        <i class="fa fa-angle-double-up"></i>
      </a>
    </div>
  </footer>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      footerData: null,
      showBackToTop: false,
    };
  },
  mounted() {
    this.fetchFooterData();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    async fetchFooterData() {
      try {
        const response = await axios.get('https://jangavoice.com/siteupdate/api/footer/');
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.footerData = response.data[0];
        } else {
          console.error('Unexpected response format for footer data:', response.data);
        }
      } catch (error) {
        console.error('Error fetching footer data:', error);
      }
    },
    handleScroll() {
      this.showBackToTop = window.scrollY > 300; // Show button after scrolling down 300px
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped>
footer a {
  transition: color 0.3s ease;
}
footer a:hover {
  color: #90cdf4; /* Light blue on hover */
}
.uppercase {
  text-transform: uppercase;
}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: #f48a26; /* Button color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px 15px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition */
}
.back-to-top:hover {
  background-color: #e07b1c; /* Darker shade on hover */
}
</style>
