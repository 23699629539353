<template>
  <header class="bg-white py-4 shadow-md z-10 sticky top-0" :class="{'scrolled': isScrolled}">
    <div class="container mx-auto px-4">
      <div class="flex items-center justify-between">
        <!-- Logo on the left -->
        <router-link to="/" class="flex items-center">
          <img :src="require('@/assets/logo.jpg')" alt="Logo" class="h-16 sm:h-18 lg:h-50" />
        </router-link>
        <!-- Mobile Menu Button -->
        <button @click="toggleMobileNav" class="md:hidden text-blue-500 focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-8 h-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
        <!-- Navigation links for larger screens -->
        <div class="md:flex items-center space-x-6 hidden">
          <router-link to="/" class="menu-link">Home</router-link>
          <router-link to="/about" class="menu-link">About</router-link>
          <router-link to="/reportedcase" class="menu-link">Report Case</router-link>
          <router-link to="/watersourcestatus" class="menu-link">Water Source Status</router-link>
          <router-link to="/feeds" class="menu-link">Feeds</router-link>
          <router-link to="/contact" class="menu-link">Contact</router-link>
        </div>
        <!-- Search Form -->
        <form @submit.prevent="performSearch" class="hidden md:flex items-center space-x-4">
          <div class="input-group">
            <input v-model="searchQuery" type="text" class="form-control" placeholder="What are you looking for..." />
            <div class="input-group-append">
              <span class="input-group-text bg-transparent text-primary ml-2"> <!-- Added margin -->
                <i class="fa fa-search"></i>
              </span>
            </div>
          </div>
        </form>
        <!-- Sign Up button for larger screens -->
        <nav class="hidden md:flex items-center space-x-4">
          <button @click="showSignUpForm" class="bg-blue-600 text-white py-2 px-6 rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out">
            Subscribe
          </button>
        </nav>
      </div>
    </div>
    <!-- Mobile Navigation Links -->
    <div :class="{'mobile-nav visible': showMobileNav, 'mobile-nav hidden': !showMobileNav}">
      <div class="close-btn">
        <button @click="toggleMobileNav" class="text-gray-700 text-xl">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-8 h-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <router-link to="/" class="block menu-link">Home</router-link>
      <router-link to="/about" class="block menu-link">About</router-link>
      <router-link to="/reportedcase" class="block menu-link">Report Case</router-link>
      <router-link to="/watersourcestatus" class="block menu-link">Water Source Status</router-link>
      <router-link to="/feeds" class="block menu-link">Feeds</router-link>
      <router-link to="/contact" class="block menu-link">Contact</router-link>
    </div>
    <!-- Sign-up Modal -->
    <div v-if="showSignUpModal" class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-70 z-50">
      <div class="bg-white p-6 rounded-lg shadow-lg">
        <h2 class="text-2xl font-semibold mb-4">{{ modalMessage }}</h2>
        <input v-model="email" @input="validateEmail" type="email" class="w-full p-2 mb-2 border border-gray-300 rounded-lg" placeholder="Enter your email" />
        <span v-if="emailError" class="text-red-500 text-sm">Please enter a valid email.</span>
        <button @click="subscribe" :disabled="isSubscribed || emailError" class="bg-blue-600 text-white py-2 px-4 rounded-lg w-full hover:bg-blue-700">
          {{ isSubscribed || subscriptionError ? 'Close' : 'Subscribe' }}
        </button>
        <button v-if="!isSubscribed && !subscriptionError" @click="closeSignUpForm" class="text-blue-500 hover:underline mt-2 block text-center">Cancel</button>
      </div>
    </div>
  </header>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      showSignUpModal: false,
      showMobileNav: false,
      email: '',
      isSubscribed: false,
      subscriptionError: false,
      modalMessage: 'Subscribe to our updates',
      searchQuery: '',
      emailError: false,
    };
  },
  methods: {
    validateEmail() {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
      this.emailError = !re.test(this.email);
    },
    async subscribe() {
      this.validateEmail(); // Validate email before subscribing
      if (!this.email || this.emailError) {
        this.subscriptionError = true;
        this.modalMessage = 'Please enter a valid email.';
        return;
      }
      try {
        const response = await axios.post('https://jangavoice.com/adminpanel/api/subscribers/', { email: this.email });
        if (response.status === 201) {
          this.isSubscribed = true;
          this.modalMessage = 'Thank you for subscribing!';
        } else if (response.status === 400) {
          this.subscriptionError = true;
          this.modalMessage = 'You have already subscribed.';
        }
      } catch (error) {
        console.error('Error subscribing:', error);
        this.subscriptionError = true;
        this.modalMessage = 'You have already subscribed.';
      } finally {
        if (this.isSubscribed || this.subscriptionError) {
          setTimeout(() => {
            this.closeSignUpForm();
          }, 2000);
        }
      }
    },
    closeSignUpForm() {
      this.showSignUpModal = false;
      this.email = '';
      this.isSubscribed = false;
      this.subscriptionError = false;
      this.emailError = false; // Reset email error
      this.modalMessage = 'Subscribe to our updates';
    },
    showSignUpForm() {
      this.showSignUpModal = true;
    },
    toggleMobileNav() {
      this.showMobileNav = !this.showMobileNav;
    },
    performSearch() {
      console.log('Searching for:', this.searchQuery);
      // Implement your search logic here
    },
  },
};
</script>

<style scoped>
.menu-link {
  font-size: 16px;
  color: #0056b3;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  padding: 8px 12px;
  border-radius: 4px;
}
.menu-link:hover {
  background-color: #0056b3;
  color: #CE1F4E;
  color: #f48a26;
  transform: scale(1.05);
}

.mobile-nav {
  width: 70%;
  max-width: 300px;
  background-color: #CE1F4E;
  background-color: #f48a26;
  height: 50%;
  position: fixed;
  top: 0;
  right: 0; /* Align mobile menu to the right */
  z-index: 20;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}
.mobile-nav,
.container {
  border: 1px solid #f48a26;
}
.mobile-nav.hidden {
  transform: translateX(100%); /* Adjust for right alignment */
}
.mobile-nav.visible {
  transform: translateX(0);
}
.close-btn {
  text-align: right;
  margin-top: 10px;
}
.input-group {
  display: flex;
}
.form-control {
  padding: 0.5rem;
  border: 1px solid #f48a26;
  border-radius: 4px;
}
.input-group-append {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .md\\:hidden {
    display: none;
  }
  .fixed {
    position: fixed;
  }
}
</style>
